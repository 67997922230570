import React from "react";

const Footer = () => {
  return (
    <>
      <div className="shane_tm_section">
        <div className="shane_tm_copyright">
          <div className="container">
            <div className="inner">
              <div className="svg">

                  &copy; {new Date().getFullYear()} Caroline Reed. All rights
                  reserved &nbsp;|
                  <a href="img/resume/Terms-and-Conditions.pdf" download>
                    &nbsp; Terms & Conditions </a>

                    <div className="ig-link">
                       <a href="https://www.instagram.com/chr_pictures/" target="_blank" rel="noreferrer">
                      &nbsp; Instagram &nbsp;
                     

                      <img className="svg"
                        src="img/svg/social/instagram-white.svg"
                        alt="Instagram"/>
                        </a>
                   </div>
                 
              </div>
              {/* End inner */}
            </div>
          </div>
          {/* End shane_tm_copyright */}
        </div>
      </div>
    </>
  );
};

export default Footer;
