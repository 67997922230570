import React, { useState } from "react";
import Modal from "react-modal";
import PriceForm from "./PriceForm";

Modal.setAppElement("#root");

const PriceOptions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  return (
    <div className="shane_tm_section" id="news">
      <div className="shane_tm_news">
        <div className="container">
          <div className="shane_tm_title">
            <h3>Prices & Rates</h3>
          </div>
          {/* End shane_tm_title */}
          <div className="news_list">
            <ul>
              <li data-aos="fade-up" data-aos-duration="1200">
                <div className="list_inner">
                  <div className="image" onClick={toggleModalOne}>
                    <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL + "img/news/cut-out-prices.jpg"
                        })`,
                      }}
                    ></div>
                  </div>
                  {/* End image */}

                  <div className="details">
                    <h3 className="title" onClick={toggleModalOne}>
                      Per Item Cut Outs
                    </h3>
                  </div>
                  {/* End details */}

                  {/* START MODAL */}
                  <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModalOne}
                    contentLabel="My dialog"
                    className="custom-modal"
                    overlayClassName="custom-overlay"
                    closeTimeoutMS={500}
                  >
                    <div className="shane_tm_modalbox_news">
                      <button className="close-modal" onClick={toggleModalOne}>
                        <img src="/img/svg/cancel.svg" alt="close icon" />
                      </button>
                      {/* End close icon */}
                      <div className="box_inner">
                        <div className="description_wrap scrollable">
                          <div className="image">
                            <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${
                                  process.env.PUBLIC_URL +
                                  "img/news/cut-out-prices.jpg"
                                })`,
                              }}
                            ></div>
                          </div>
                          {/* End image */}
                          <h3 className="title">Per Image Price Calculator</h3>

                          <div className="description">
                            <p>
                              The prices listed below are for standard items of clothing and accessories,
                              finished as cut out images on a
                              pure white background (#ffffff). Some garments,
                              such as halter-neck and strappy tops require
                              multiple images and take additional time in post
                              production. These are priced accordingly. Please
                              ask for a quote.
                            </p>
                            <p>
                              There is a fixed handling and processing charge of
                              £30 for each order. Delivery and return is not
                              included in this fee.
                            </p>
                            <p>
                              The first image of each item is priced as follows:
                            </p>
                            <div className="pricinglist">
                              <ul>
                                <li>
                                  The first 1 to 5 items are priced at £22 each
                                </li>
                                <li>The next 5 items at £19.50 each</li>
                                <li>The next 10 items at £17.50 each</li>
                                <li>Further items at at £15.50 each</li>
                                <br></br>
                                <li>
                                  {" "}
                                  Additional cutout images of the same item, for
                                  example back views are £11 per image.{" "}
                                </li>
                                <li>
                                  {" "}
                                  Detail shots, that are NOT cut out, are £11 for
                                  a set of up to 3 images of the same item.
                                </li>
                              </ul>
                            </div>
                            <p>
                              <br></br>
                              Please use the pricing calculator below or contact
                              me if you have more complex requirements.
                            </p>

                            <p>
                              <b> Jewellery</b> - Prices are
                              calculated by time used, time based rates apply. Please get in touch with your
                              requirements and I can provide an estimate.&nbsp;
                            
                            </p>
                          </div>
                          <div className="details">
                            <PriceForm />
                          </div>
                          <div className="tc-linkprices">
                              <a href="img/resume/Terms-and-Conditions.pdf"
                                download
                              >
                               Terms & Conditions can be downloaded here.{" "}
                              </a>
                             
                              </div>
                          {/* End description */}
                        </div>
                      </div>
                      {/* End box inner */}
                    </div>

                    {/* End modal box news */}
                  </Modal>
                  {/* End modal */}
                </div>
              </li>
              {/* End single blog */}

              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <div className="list_inner">
                  <div className="image" onClick={toggleModalTwo}>
                    <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL +
                          "img/news/beauty-1000-670.jpg"
                        })`,
                      }}
                    ></div>
                  </div>
                  {/* End image */}

                  <div className="details">
                    <h3 className="title" onClick={toggleModalTwo}>
                    Time Based Rates
                    </h3>
                  </div>
                  {/* End details */}

                  <Modal
                    isOpen={isOpen2}
                    onRequestClose={toggleModalTwo}
                    contentLabel="My dialog"
                    className="custom-modal"
                    overlayClassName="custom-overlay"
                    closeTimeoutMS={500}
                  >
                    <div className="shane_tm_modalbox_news">
                      <button className="close-modal" onClick={toggleModalTwo}>
                        <img src="/img/svg/cancel.svg" alt="close icon" />
                      </button>
                      {/* End close modal */}
                      <div className="box_inner">
                        <div className="description_wrap scrollable">
                          <div className="image">
                            <img
                              src="/img/placeholders/beauty-1000-670.jpg"
                              alt="thumb"
                            />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${
                                  process.env.PUBLIC_URL +
                                  "img/news/beauty-1000-670.jpg"
                                })`,
                              }}
                            ></div>
                          </div>
                          {/* End image */}
                          <div className="details">
                            <h3 className="title">Time Based Rates</h3>
                          </div>
                          {/* End details */}
                          <div className="description">
                            <p>
                              For creative photography, working to your brief, in my studio,
                              I use this scheme as a guide to price work.
                            </p>

                            <div className="pricinglist">
                              <ul>
                                <li>
                                  The first hour is <b>£280</b>
                                </li>
                                <li>
                                  Subsequent hours are <b>£80 per hour</b>
                                </li>
                              </ul>
                              
                            </div>
                            <p>
                              For example: 1 hour setup, 5 hours of photography and 2 hours of computer processing would be £280 + (7x £80) = £840.
                            </p>
                            <br></br>
                            <p>
                              Please get in contact with your requirements for an 
                              estimate, as the number of items or shots achieved, will depend on the brief.
                              Prices include the use of the studio in Hampshire.
                              Shoots can also take place at a location or studio
                              of your choice, but would need to be priced accordingly.
                            </p>
                            <br></br>
                          </div>
                          <div className="tc-link">
                            <a
                              href="img/resume/Terms-and-Conditions.pdf"
                              download
                            >
                              Terms & Conditions can be downloaded here.{" "}
                            </a>
                          </div>
                          {/* End description */}

                          {/* End news share */}
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {/* End modal */}
                </div>
                {/* End list inner */}
              </li>
              {/* End single blog */}

              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="list_inner">
                  <div className="image" onClick={toggleModalThree}>
                    <img
                      src="/img/placeholders/4-3.jpg"
                      alt="thubags on pink background"
                    />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL +
                          "img/news/Caroline_Reed_bags.jpg"
                        })`,
                      }}
                    ></div>
                  </div>
                  {/* END IMAGE */}

                  <div className="details">
                    <h3 className="title" onClick={toggleModalThree}>
                      Creative Stills & Video
                    </h3>
                  </div>
                  {/* END DETAILS */}

                  {/* START MODAL */}
                  <Modal
                    isOpen={isOpen3}
                    onRequestClose={toggleModalThree}
                    contentLabel="My dialog"
                    className="custom-modal"
                    overlayClassName="custom-overlay"
                    closeTimeoutMS={500}
                  >
                    <div className="shane_tm_modalbox_news">
                      <button
                        className="close-modal"
                        onClick={toggleModalThree}
                      >
                        <img src="/img/svg/cancel.svg" alt="close icon" />
                      </button>
                      {/* END CLOSE MODAL */}
                      <div className="box_inner">
                        <div className="description_wrap scrollable">
                          <div className="image">
                            <img
                              src="/img/placeholders/Caroline_Reed_bags.jpg"
                              alt="bags on pink background"
                            />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${
                                  process.env.PUBLIC_URL +
                                  "img/news/Caroline_Reed_bags.jpg"
                                })`,
                              }}
                            ></div>
                          </div>
                          {/* END IMAGE */}
                          <div className="details">
                            <h3 className="title">Creative Images & Video</h3>
                          </div>
                          {/* END DETAILS */}
                          <div className="description">
                            <p>
                              For multiple products to be included in one image,
                              flat lays and creative product stills and video using
                              coloured backgrounds, props or special lighting
                              techniques the price is charged for the time used
                              as shown in the 'Rates' section. The time charged incudes the
                              whole 'job' i.e. the time it takes to construct
                              and test the set, process the images and strip
                              down the set.
                            </p>
                            <div className="tc-link">
                              <a
                                href="img/resume/Terms-and-Conditions.pdf"
                                download
                              >
                                Terms & Conditions can be downloaded here.{" "}
                              </a>
                            </div>
                          </div>
                          {/* END DESCRIPTION */}
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                {/* END LIST INNER */}
              </li>

              {/* End single blog */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceOptions;
