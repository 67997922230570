import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const partnerList = [
  { img: "Newlab-Sneakers-White-and-Zebra-Cutout.jpg", animationDealay: "" },
  { img: "Diana-Wilson-Arcana-Cactus-Wool-Scarf-Loop.jpg", animationDealay: "30" },
  { img: "straw-hat.jpg", animationDealay: "60" },
  { img: "Cream-bag.jpg", animationDealay: "90" },
  { img: "Ellie&Bea001.jpg", animationDealay: "120" },
  { img: "Shoe-Cut-Out-back.jpg", animationDealay: "150" },
  { img: "Wallet-Cut-Out.jpg", animationDealay: "180" },
  { img: "necklace.jpg", animationDealay: "210" },
];

export default function BrandAnimation() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <ul>
      <Slider {...settings}>
        {partnerList.map((val, i) => (
          <li className="item" key={i}>
            <img
              src={`img/partners/${val.img}`}
              alt="partners brand"
              data-aos="fade"
              data-aos-duration="1200"
              data-aos-delay={val.animationDealay}
            />
          </li>
        ))}
        {/* End single parter image */}
      </Slider>
    </ul>
  );
}
