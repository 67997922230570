import React from "react";
import Header from "../components/Header";
import Slider from "../components/SliderFour";
import About from "../components/About";
import Portfolio from "../components/PortfolioTwo";
import Video from "../components/VideoThree";
import Brand from "../components/BrandAnimation";
import Testimonial from "../components/TestimonialTwo";
import PriceOptions from "../components/PriceOptions";
import CallToAction from "../components/CallToActionTwo";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="home-four">
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Slider Section */}

      <About />
      {/* End About Section */}

      <Portfolio />
      {/* End Portfolio Section */}



      <div className="shane_tm_section">
        <div className="shane_tm_partners">
          <div className="container">
            <div className="partners_inner">
              <Brand />
            </div>
          </div>
        </div>
      </div>
      {/* End shane_tm_partners */}

      <Video />
      {/* End Video Section */}



      <div className="shane_tm_section">
        <div className="shane_tm_testimonials">
          <div className="container">
            <div className="testimonials_inner">
              <div
                className="left"
                data-aos="fade-right"
                data-aos-duration="50"
              >
                <div className="shane_tm_title">
                <span>Testimonials</span>
             
                <h3>What clients say...</h3>
               
                </div>
                
              </div>

              <div
                className="right"
                data-aos="fade-right"
                data-aos-duration="50"
              >
                <Testimonial />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End  shane_tm_testimonials */}

      <PriceOptions/>
         {/* End Price Options Section */}


      <CallToAction />
      {/* End CallToAction */}

      <Footer />
      {/* End Footer Section */}
    </div>
  );
};

export default Home;
