import React, { useRef, 
  // useEffect
 } from "react";
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const {
    register,
    handleSubmit,
    // submittedData,
    // reset,
    formState,
    formState: { errors, 
    // isSubmitSuccessful 
    }
  } = useForm();



  const form = useRef();
  const sendEmail = () => {

   // e.preventDefault();

    emailjs.sendForm('service_265clbp', 'template_lw0sfi1', form.current, 'sq543fqM6iBdN7ypl')
      .then((result) => {
          console.log(result.text);
          //alert("Message sent");
          //console.log("Message submited: " + JSON.stringify(data));
    
      }, (error) => {
          console.log(error.text);
          //alert("Message Failed");
          });
  };


  //useEffect(() => {
  //   if (formState.isSubmitSuccessful) {
  //     reset();
  //   }
  // }, [formState, submittedData, reset]);


  return (
    <>
      <form ref={form} className="contact_form" onSubmit={handleSubmit(sendEmail)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="Name"
              />
              {errors.name && errors.name.type === "required" && (
                <span>Name is required</span>
              )}
            </li>
            {/* End first name field */}

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "Req'd",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="Email"
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>
            {/* End email name field */}

            <li>
              <textarea
                {...register("message", { required: true })}
                placeholder="Message"
              ></textarea>
              {errors.message && <span>Message is required.</span>}
            </li>
            {/* End message  field */}
 
              {formState.isSubmitSuccessful && <li><span>Thank you, your message has been sent.</span></li>}
            {/* End thanks  field */}

          </ul>
        </div>

        {!(formState.isSubmitSuccessful) && 
        <div className="tokyo_tm_button">
          <button type="submit" className="white-fill-bg fill-black">
            Send Message
          </button>
        </div> }
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
