import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery, Item } from "react-photoswipe-gallery";

// import { tabListContent } from "./PortfolioData"
// import * as constants from "./PortfolioData"
// import { tabList, tabListContent } from "./PortfolioData"


const tabList = ["Selection", "Lifestyle", "E-Commerce on Model", "Beauty & Hair", "Creative Product"];

const tabListContent = [
  {
    porftoliItems: [
  
      {
       img: "/img/portfolio/Hair-beauty10.jpg",
         picWidth: 665,
       },
       {
        img: "https://crphoto.sirv.com/Hat-Kite/images.spin",
        picWidth: 665,
      },
      {
        img: "/img/portfolio/BB_AW23_0192.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/016.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Belinda_077R.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/AtLast_Model_1758 1.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Jeans-ghost-mannequin.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Tabitha_Webb_0020.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Popski-dress.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/fashion-studio-04.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/060.jpg",
        picWidth: 665,
      },

      {
        img: "/img/portfolio/AtLast_Model_0173-683x1024.jpg",
        picWidth: 665,

      },
      
    ],
  },
  {

    porftoliItems: [
      {
        img: "/img/portfolio/AtLast__02.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/063.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Natalie67.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Fitness-Image.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Wendy2.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/062.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Wendy4.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/068.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/018.jpg",
        picWidth: 665,

      },
   
    ],
  },
  {

    porftoliItems: [
      {
        img: "/img/portfolio/Evalina-CoutureGallery_159-Edit.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Elouise-CoutureGallery_482-Edit.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Ellen-CoutureGallery_579-Edit.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/StolenGoat_209-Edit.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/StolenGoat_179-Edit.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/StolenGoat_190-Edit.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/fashion-studio-02.jpg",
        picWidth: 665,
  
      },
      {

        img: "/img/portfolio/fashion-studio-01.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/fashion-studio-03.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/Belinda_019-2.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/AtLast_Model_0173-683x1024.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/AtLast_Model_1623 1.jpg",
  
      },
    ],
  },
  {

    porftoliItems: [
      {
        img: "/img/portfolio/Hair-beauty.jpg",
        picWidth: 665,

      },
      {
        img: "/img/portfolio/Kristie34.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/Model002.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/Hair-beauty02.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/Hair-beauty03.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/1Amie05.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/Hair-beauty04.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/077.jpg",
        picWidth: 665,
  
      },
      {
        img: "/img/portfolio/Leanna.jpg",
        picWidth: 665,
  
      },
   
    ],
  },
  {
    porftoliItems: [
      {
        img: "/img/portfolio/product08.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product02.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product11.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product10.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product09.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product17.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product12.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product13.jpg",
        picWidth: 1000,
      },
      {
        img: "/img/portfolio/product07.jpg",
        picWidth: 1000,
      },
      
    ],  
  }
];



const Portfolio = () => {
  return (
    <div className="shane_tm_section" id="portfolio">
      <div className="shane_tm_portfolio">
        <div className="container">
          <div className="positon-relative">
            <div className="shane_tm_title">
              <div className="title_flex">
                <div className="left">
                 
                  <h3>Portfolio</h3>
                </div>
              </div>
            </div>
            {/* End shane_tm_title */}

            <div className="portfolio_filter">
              <Tabs>
                <TabList>
                  {tabList.map((val, i) => (
                    <Tab key={i}>{val}</Tab>
                  ))}
                </TabList>
                {/* End tablist */}

                <div className="portfolio_list has-effect">
                  <Gallery>
                    {tabListContent.map((tabContent, j) => (
                      <TabPanel key={j}>
                        <ul className="gallery_zoom">
                          {tabContent.porftoliItems.map((val, k) => (
                            <li
                              key={k}
                              data-aos="fade-in"
                              data-aos-duration="1200"
                              data-aos-delay={val.delayAnimation}
                            >
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                { val.img.substr(-5) === ".spin"
                                  ? 
                                  (<Item
                                    width={val.picWidth}
                                    height={1000}
                                    >
                                    {({ ref, open }) => (
                                      <div class="Sirv" 
                                        alt="portfolio"
                                        role="button"
                                        ref={ref}
                                        onClick={open}
                                        data-src={val.img}>
                                      </div>
                                    )}
                                  </Item>)
                                  :
                                  (<Item
                                    original={val.img}
                                    thumbnail={val.img}
                                    width={val.picWidth}
                                    height={1000}
                                    >
                                    {({ ref, open }) => (
                                      <img
                                        src={val.img}
                                        alt="portfolio"
                                        role="button"
                                        ref={ref}
                                        onClick={open}
                                      />
                                    )}
                                  </Item>)
                                }


                                  {/* <Item
                                    original={val.img}
                                    thumbnail={val.img}
                                    width={val.picWidth}
                                    height={1000}
                                  >
                                    {({ ref, open }) => (
                                      <img
                                        src={val.img}
                                        alt="portfolio"
                                        role="button"
                                        ref={ref}
                                        onClick={open}
                                      />
                                    )}
                                  </Item> */}
                                </div>

                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPanel>
                    ))}
                  </Gallery>
                  {/* End tabpanel */}
                </div>
                {/* End list wrapper */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
