import React from "react";
import PriceOptions from "../components/PriceOptions";

const Calc = () => {
  return (
    <div className="home-four">
      
      <PriceOptions />
      {/* your form */}


    </div>
  );
};

export default Calc;
