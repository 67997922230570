import React from "react";
//import TextLoop from "react-text-loop";

const Slider = () => {
  return (
    <div className="slider-four">
      <div className="shane_tm_hero" id="home" data-style="one">
        <div className="frame-layout__particles">
        </div>
        <div className="background">
          <div
            className="image"
            style={{ backgroundImage: "url(img/slider/Agata084Hr.jpg)" }}
          ></div>
        </div>
        {/* End .background */}

        <div className="container">
          <div className="content">
            <div className="name_wrap">
              <h3>
                <span>
                  Caroline Reed 
                  <br />
                </span>{" "}
                {/* <TextLoop>
                  <span className="loop-text">E-commerce</span>
                  <span className="loop-text"> Lifestyle</span>
                  <span className="loop-text"> Cutouts & Mannequin</span>
                </TextLoop>{" "} */}
                <span className="overlay_effect"></span>
              </h3>
            </div>
            {/* End title */}

            <div className="job_wrap">
              <span className="job">
                Photography
                <span className="overlay_effect"></span>
              </span>
            </div>
            {/* End designation */}
            <a
              href="#portfolio"
              className="white-fill-bg btn-outline btn-medium"
            >
              PORTFOLIO
              <span className="overlay_effect"></span>
            </a>
          </div>
          {/* End content */}
        </div>
        {/* End .container */}
      </div>
    </div>
  );
};

export default Slider;
