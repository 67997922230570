import React, { useState } from 'react';

const PriceForm = () => {
  const [refs] = useState({
    mainCount: React.createRef(),
    addCount: React.createRef(),
    price: React.createRef(),
  });

  const [vars] = useState({
    mainCount: 0,
    addCount: 0,
    price: "",
  });
  
  // Note that the above refs & vars are not really variables use to update the state (never update)
  // Just variables used to store data. like member variables (int a class component)
  
  const costCalc = (mainN, addN) => {

    const callQuantity = 301; /* total shots equal or above this count - ask to call */
    const baseFee = 30;  /* one off fee */
    
    const quant = [  5,  5, 10, callQuantity ];
    const rate = [ 22, 19.50, 17.50, 15.50 ];
    
    const addPrice = 11;
    
    /* ====== */
    
    if (mainN <=0) return 'You must have some "First images" for this to work! :-)';
    
    if (mainN >= callQuantity) return 'Please call for a quote';
    
    if ( mainN !== Math.floor(mainN) || addN !== Math.floor(addN) ) return 'Please enter a whole number in each field';
    
    let rq = mainN;
    let t = (rq>0 ? baseFee : 0);
    
    for(let i=0;(i < quant.length) && (rq > 0);i++){
        t = t + Math.min(rq,quant[i]) * rate[i];
        rq = rq-quant[i];
        };
    
    t = t + addN * addPrice;
    
    const res = '£ ' + t.toFixed(2);

    return  res;
};
const onChange = (e) => {
    const { name, value } = e.target;
    vars[name] = value;
    // Could've used vars, but just use refs because we keep references to all 3
    const mainCount = parseInt(refs.mainCount.current.value, 10);
    const addCount = parseInt(refs.addCount.current.value, 10);


    // But you need refs to update

    const res = costCalc(mainCount,addCount);
    refs.price.current.value = res;
  };

  return (
    <form>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label htmlFor="mainCount">Please enter the quantity of FIRST cutout image of an item (e.g. Main front image)* </label>
        <input ref={refs.mainCount} onChange={onChange} defaultValue={vars.mainCount} min="0" name="mainCount" id="mainCount" type="number" />
        <label htmlFor="addCount">Enter the quantity of ADDITIONAL cutout image of an item (e.g. garment back or detail close up)</label>
        <input ref={refs.addCount} onChange={onChange} defaultValue={vars.addCount} min="0" name="addCount" id="second" type="number" />
        <label htmlFor="price">Total cost  </label>
        <input disabled ref={refs.price} defaultValue={vars.price} name="price" id="price" type="text" />
      </div>
    </form>
  );
};

export default PriceForm;


